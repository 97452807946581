<template>
  <div class="printButton fix-height">
    <uello-button
      color="gray"
      class="fix-height ml-4 fix-width right-button"
      icon="printer"
      iconSize="small"
      v-if="showButton"
      :disabled="loadingPrint"
      :title="$t('form.buttons.printLabels')"
      @click="printLabels"
    >
      <span v-if="loadingPrint"> {{ $t('agGrid.localeText.loadingOoo') }}</span>
      <span v-else> {{ $t('form.buttons.printLabels') }}</span>
    </uello-button>
    <b-modal :title="$t('modals.noTag.title')" v-model="showNoTagModal" ok-only>
      {{ noTagMessage }}
    </b-modal>
    <vue-easy-print tableShow v-show="false" ref="easyPrint">
      <template>
        <div class="print-html"></div>
      </template>
    </vue-easy-print>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import vueEasyPrint from 'vue-easy-print';
import * as types from '@/store/types';
import { UelloButton } from '@uello/componentello';

const JsBarcode = require('jsbarcode');
const qrcode = require('@/assets/qrcode/qrcode');
const uelloLogo = require('@/assets/images/logo-uello-preto.png');

const TAGS_PER_PAGE = 8;

export default {
  name: 'print-labels',
  data() {
    return {
      visible: true,
      selected: false,
      showNoTagModal: false,
      noTagMessage: '',
    };
  },
  components: {
    vueEasyPrint,
    UelloButton,
  },
  computed: {
    ...mapGetters({
      getSelected: types.GETTER_SELECTED,
      loadingPrint: types.GETTER_LOADING_PRINT,
      getOrdersLabels: types.GETTER_PRINT_LABELS,
    }),
    showButton() {
      return this.getSelected.length > 0;
    },
  },
  methods: {
    ...mapActions({
      printSelectedOrders: types.ACTION_ORDER_LABELS_PRINT,
      turnoffPrintLoading: types.ACTION_TURNOFF_PRINT_LOADING,
    }),
    async printLabels() {
      await this.printSelectedOrders();

      const orderLabelsIds = this.getOrdersLabels.map(item => `${item.order}`);
      const noTag = this.getSelected.filter(item => !orderLabelsIds.includes(item));
      if (noTag.length) {
        this.noTagMessage = this.$t('modals.noTag.message', {
          ids: noTag.join(', '),
        });
        this.showNoTagModal = true;
      }

      this.createLabelsToPrint(this.getOrdersLabels);
    },
    createLabelsToPrint(orderLabels) {
      if (orderLabels.length === 0) {
        return;
      }

      const labels = [];

      let index = 1;
      let tagsRemaining = TAGS_PER_PAGE;

      labels.push('<div class="page-box">');

      let lastOrder = 0;
      orderLabels.forEach(order => {
        if (lastOrder !== order.order) {
          lastOrder = order.order;
          index = 1;
        }

        labels.push(`
            <div class="tag-box">
              <div class="qr-code-row">
                  <div class="qrcode qrcode_${order.order}_${index}"></div>
                  <div class="qr-code-info">
                      <div class="info-line">
                          <span class="iata">
                              ${order.iata}
                          </span>
                          <img src="${uelloLogo}" alt="Uello" />
                      </div>
                      <div class="info-line">
                          <span class="postcode">
                              ${this.postcodeFormat(order.postcode)}
                          </span>
                          <span class="postcode">ROTA</span>
                      </div>
                      <div class="info-line">
                          <span class="partner-name">
                              ${this.resizeString(order.company)}
                          </span>
                          <span class="postcode route-info">
                              ${order.route || 'N/D'}
                          </span>
                      </div>
                  </div>
              </div>
              <div class="order-info">
                  <div>
                      <p class="order-details">
                          ${order.customer}
                      </p>
                      <p class="order-details">
                          ${this.resizeString(order.address, 100)}
                      </p>
                  </div>
                  <div>
                      <p class="order-details">
                          Criação: ${order.createdAt}
                      </p>
                      <div class="info-line sensive">
                          <span>VOL ${index}/${order.volumes}</span>
                          <span>NF ${order.invoice}</span>
                      </div>
                       <div class="info-line sensive">
                          <span></span>
                          <span class="print-operation">${order.operation}</span>
                       </div>
                  </div>
              </div>
              <div class="barcode-area">
                  <svg class="barcode barcode_${order.order}_${index}"></svg>
              </div>
            </div>
          `);

        tagsRemaining -= 1;

        if (tagsRemaining <= 0) {
          tagsRemaining = TAGS_PER_PAGE;

          labels.push('</div>');
          labels.push('<div style="break-after:page"></div>');
          labels.push('<div class="page-box">');
        }

        index += 1;
      });

      labels.push('</div>');

      document.querySelector('.print-html').innerHTML = labels.join('');

      lastOrder = 0;
      orderLabels.forEach(order => {
        if (lastOrder !== order.order) {
          lastOrder = order.order;
          index = 1;
        }

        const errorCorrectionLevel = 'L';
        const qr = qrcode(0, errorCorrectionLevel);

        qr.addData(order.identification);
        qr.make();

        const svgQr = qr.createSvgTag(4, 0);
        document.querySelector(`.print-html .qrcode_${order.order}_${index}`).innerHTML = svgQr;

        JsBarcode(`.print-html .barcode_${order.order}_${index}`, order.identification, {
          textPosition: 'top',
          height: 80,
        });

        index += 1;
      });

      setTimeout(() => {
        this.$refs.easyPrint.print();
      }, 200);
    },
    postcodeFormat(postCodeToFormat) {
      let splited = postCodeToFormat.replace(/-/g, '').split('');
      splited = splited.reduce((accumulator, item, splitedIndex) => {
        accumulator = [...accumulator, splitedIndex === 5 ? '-' : '', item];
        return accumulator;
      }, []);

      return splited.join('');
    },
    resizeString(str, size = 16) {
      if (str.length <= size) {
        return str;
      }
      return `${str
        .split('')
        .splice(0, size)
        .join('')
        .trim()}...`;
    },
  },
  watch: {},
  mounted() {
    this.selected = this.getSelected;
    this.turnoffPrintLoading();
  },
};
</script>

<style lang="scss">
.printButton {
  height: 40px;

  .right-button {
    float: right;
    margin-right: 16px;
  }

  .fix-width {
    width: 165px;
  }
}

.tag-box {
  margin: 12px;
  padding: 15px;
  border: black 1px solid;
  width: 45%;
}

.page-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.print-html {
  width: 100%;
  padding: 12px;
  color: #333;
  font-family: 'Roboto', sans-serif;
}

.print-html .logo {
  max-height: 24px;
  max-width: auto;
}

.print-html .qr-code-row {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-size: 12px;
}

.print-html .qrcode {
  width: 100%;
  height: 100%;
  max-width: 84px;
  max-height: 84px;
}

.print-html .qrcode > svg {
  max-width: 84px;
  max-height: 84px;
}

.print-html .qr-code-info {
  display: flex;
  flex: 1;
  margin-left: 8px;
  flex-direction: column;
}

.print-html .qr-code-info img {
  max-height: 26px;
  max-width: auto;
}

.print-html .info-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.print-html .info-line span + span {
  margin-left: 16px;
}

.print-html .info-line.sensive {
  font-weight: bold;
  font-size: 12px;
  margin-top: 8px;
}

.print-operation {
  display: block;
  font-weight: bold;
  margin-top: 8px;
  font-size: 14px;
  line-height: 14px;
  padding: 5px;
  color: white !important;
  background-color: black !important;
  -webkit-print-color-adjust: exact;
}

.print-html .qr-code-info .iata,
.print-html .qr-code-info .postcode {
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  margin: 0;
}

.print-html .qr-code-info .route-info {
  display: block;
  width: 96px;
  text-align: right;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  margin: 0;
}

.print-html .qr-code-info .partner-name {
  flex: 1;
  margin-top: 6px;
  margin-right: 26px;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  padding: 5px;
  color: white !important;
  background-color: black !important;
  -webkit-print-color-adjust: exact;
}

.print-html .order-info {
  display: flex;
  width: 100%;
  margin-top: 8px;
}

.print-html .order-info div {
  flex: 1;
}
.print-html .order-info div + div {
  margin-left: 16px;
}

.print-html .order-info .order-details {
  font-size: 12px;
  line-height: 16px;
  word-break: break-word;
}

.print-html .order-info .order-details + .order-details {
  margin-top: 8px;
}

.print-html .barcode-area {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  max-height: 100px;
}

.print-html .barcode-area > svg {
  max-width: 100%;
  max-height: 100%;
}

.print-html .barcode {
  max-width: 100%;
  height: auto;
  margin: 0;
}

.separator {
  page-break-after: always;
}
</style>
